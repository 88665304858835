import React, { Fragment, useMemo } from 'react'
import { gql } from '@apollo/client'
import useAdminQuery from '../../hooks/useAdminQuery'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { useUserContext } from '../../contexts'
import { isAdmin, isAppAdmin } from '../../utils'
import { navigation } from '../../constants'
import { LockClosedIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline'
import { Dialog, Transition, Disclosure } from '@headlessui/react'
import styles from './navSide.module.css'
import { Link, useLocation } from 'react-router-dom'

import useFetchSystems from '../../hooks/useFetchSystems'
import useGetSystemName from '../../hooks/useGetSystemName'

const QUERY = gql`
  query UnreadLeads($companyId: ID, $userId: ID) {
    appGuideLeadsNumbers {
      id
      result
    }
    appFormLeadsNumbers {
      id
      result
    }
    appBuyingIntentNumbers(companyId: $companyId, userId: $userId) {
      id
      systemId
      systemName
      result
    }
  }
`

export const GET_SYSTEMS_NAV = gql`
  query SystemsData($companyId: ID, $userId: ID, $systemId: ID) {
    appSystemsOnVendor(companyId: $companyId, userId: $userId, systemId: $systemId) {
      id
      active
      systemSiteData {
        systemName
        locale
      }
    }
  }
`

const ChildrenNavigation = ({ item, admin, appAdmin, isMobile = false }) => {
  const { t } = useTranslation('sidenav')
  const textSize = isMobile ? 'text-base' : 'text-sm'

  if (item.appAdmin && !appAdmin) {
    return null
  }
  return (
    <Disclosure as="div" key={item.name} className="space-y-1" defaultOpen={false}>
      {({ open }) => {
        return (
          <>
            <Disclosure.Button
              className={classNames(
                item.current
                  ? 'bg-gray-100 text-gray-900'
                  : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900 opacity-60 hover:opacity-100',
                `${textSize} border-none group w-full flex items-center pl-2 pr-1 py-2 text-left font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500`
              )}
            >
              <item.icon
                className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              <span className="flex-1">{t(item.name)}</span>
              <svg
                className={classNames(
                  open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                  'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                )}
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
              </svg>
            </Disclosure.Button>
            <Disclosure.Panel className="space-y-1">
              {item.children.map((subItem) => {
                if (subItem.admin && !admin) {
                  return null
                }

                if (subItem.appAdmin && !appAdmin) {
                  return null
                }

                return (
                  <Link
                    key={subItem.name}
                    to={subItem.underDevelopment && !admin ? '#' : subItem.href}
                  >
                    <span
                      className={classNames(
                        'tooltip',
                        subItem.current
                          ? 'bg-gray-100 text-gray-900'
                          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        subItem.underDevelopment && !admin ? 'cursor-not-allowed' : '',
                        'relative group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium rounded-md'
                      )}
                    >
                      {subItem.skipTranslate ? subItem.name : t(subItem.name)}
                      {subItem.disabled && !subItem.underDevelopment && (
                        <div className="ml-auto">
                          <LockClosedIcon
                            className={classNames(
                              subItem.current
                                ? 'text-gray-500'
                                : 'text-gray-400 group-hover:text-gray-500',
                              'flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                        </div>
                      )}
                      {subItem.underDevelopment && (
                        <div className="ml-auto">
                          <ExclamationIcon
                            className={classNames(
                              subItem.current
                                ? 'text-gray-500'
                                : 'text-gray-400 group-hover:text-gray-500',
                              'flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                        </div>
                      )}
                      {subItem.underDevelopment && (
                        <div
                          role="tooltip"
                          className="tooltip-content pointer-events-none absolute bottom-8 right-0 z-10 py-2 px-3 text-xs text-orange-600 bg-orange-100 rounded-md shadow-sm transition-opacity duration-300"
                        >
                          {t('other.underDevelopmentComingSoon')}
                        </div>
                      )}
                      {subItem.newItems && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 ml-2">
                          {subItem.newItems}
                        </span>
                      )}
                    </span>
                  </Link>
                )
              })}
            </Disclosure.Panel>
          </>
        )
      }}
    </Disclosure>
  )
}

let cx = classNames.bind(styles)

function NavSide({ sidebarOpen, setSidebarOpen }) {
  const { user } = useUserContext()
  const { data, error } = useAdminQuery(QUERY)
  const { t } = useTranslation('sidenav')
  const { availableSystems } = useFetchSystems(GET_SYSTEMS_NAV)
  const { getSystemName } = useGetSystemName()
  const location = useLocation()
  const pathname = location.pathname
  const accessRights = user?.accessRights
  const admin = isAdmin(user?.role)
  const appRole = user?.appRole
  const appAdmin = isAppAdmin(user?.appRole) || admin

  const navItemsMemo = useMemo(() => {
    const availableSystemsNavItems = availableSystems?.map((system) => {
      const href = `/system/${system.id}`

      return {
        systemId: system.id,
        href,
        name: getSystemName(system),
        current: location.pathname === href,
        skipTranslate: true,
        disabled: !system.active
      }
    })

    return navigation(location.pathname)
      .map((navItem) => {
        if (navItem.name === 'system') {
          navItem.children = availableSystemsNavItems || []
        }

        if (navItem.name === 'buyingIntents') {
          navItem.children =
            data?.appBuyingIntentNumbers.map((item) => {
              const newItems = item?.result

              return {
                ...item,
                skipTranslate: true,
                name: item.systemName,
                href: `/buying-intent-v2/${item.systemId}`,
                current: location.pathname === `/buying-intent-v2/${item.systemId}`,
                newItems: newItems > 0 ? newItems : null
              }
            }) || []

          if (data?.appBuyingIntentNumbers?.length) {
            navItem.children.push({
              name: 'buyingIntentInsights',
              href: '/buying-intent-insights/',
              current: location.pathname === `/buying-intent-insights/`
            })
          }
          if (
            data?.appBuyingIntentNumbers?.length &&
            navItem.children.length >= 0 &&
            admin /* (appAdmin || admin) BW-1179 */
          ) {
            navItem.children.push({
              name: 'notifications',
              href: '/buying-intent-settings/',
              current: location.pathname === `/buying-intent-settings/`
            })
          }
        }

        if (navItem.name === 'reviews') {
          const hasSystems = availableSystems?.length > 0

          if (!hasSystems) {
            return navItem
          }

          navItem.children.push({
            name: 'yourReviews',
            href: '/reviews/your-reviews',
            current: location.pathname === '/reviews/your-reviews'
          })

          navItem.children.push({
            name: 'reviewInsights',
            href: '/reviews/insights',
            current: location.pathname === '/reviews/insights'
          })

          navItem.children.push({
            name: 'reviewCampaigns',
            href: '#',
            underDevelopment: true
          })
        }

        return navItem
      })
      .filter((navItem) => {
        // Remove vendor settings if no systems
        if (navItem.name === 'vendorSettings') {
          return availableSystemsNavItems?.length > 0 || appAdmin
        }

        if (!navItem.children?.length) {
          return true
        }

        return !!navItem.children.length
      })
      .filter((navItem) => {
        let r = navItem.name !== 'buyingIntents' ? true : false //

        if (navItem.name === 'buyingIntents' && navItem.children?.length > 0) {
          r = true
        }

        return r
      })
  }, [availableSystems, getSystemName, location.pathname, appAdmin, data])

  if (error) {
    console.error(error)
    return (
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        <div className="flex flex-col flex-grow border-r border-gray-200 pt-3 bg-white overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            <img
              className="h-12 w-auto"
              src="/businesswith-logo.svg"
              alt="BusinessWith Logo"
            />
          </div>
        </div>
      </div>
    )
  }

  const hasAccessToGuide = admin || accessRights?.includes('SYSTEMGUIDE')
  const hasAccessToForms = admin || accessRights?.includes('FORMS')
  const hasAccessToBuyingIntent = admin || accessRights?.includes('BUYER_INTENT')
  const hasAccessToBuyingSessionIntent = accessRights?.includes('BUYER_SESSION_INTENT')
  const hasAccessToEditProfile = admin || ['EDITOR', 'ADMIN'].includes(appRole)

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img
                  className="h-8 w-auto"
                  src="/businesswith-logo.svg"
                  alt="BusinessWith Logo"
                />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  {navItemsMemo.map((item) => {
                    // Mobile menu

                    if (item.admin && !admin) {
                      return null
                    }

                    if (item.appAdmin && !appAdmin) {
                      return null
                    }

                    return !item.children ? (
                      <Link key={`navigation-mobile-${item.name}`} to={item.href}>
                        <span
                          className={classNames(
                            item.current
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            item.disabled ? 'pointer-events-none' : '',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? 'text-gray-500'
                                : 'text-gray-400 group-hover:text-gray-500',
                              'mr-4 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          {t(item.name)}
                        </span>
                      </Link>
                    ) : (
                      <ChildrenNavigation
                        key={item.name}
                        item={item}
                        admin={admin}
                        appAdmin={appAdmin}
                        isMobile={true}
                      />
                    )
                  })}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-grow border-r border-gray-200 pt-3 bg-white overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            {/* <img
          className="h-8 w-auto"
          src="https://tailwindui.com/img/logos/workflow-logo-blue-600-mark-gray-800-text.svg"
          alt="Workflow"
        /> */}
            {/* <Link href="/"> */}
            {/* <a className={styles.navlogo}> */}
            {/* <Image
              src="/businesswith-logo.svg"
              alt="BusinessWith Logo"
              width={113}
              height={46}
            /> */}
            <img
              className="h-12 w-auto"
              src="/businesswith-logo.svg"
              alt="BusinessWith Logo"
            />
            {/* </a> */}
            {/* </Link> */}
          </div>
          <div className="mt-3 flex-grow flex flex-col">
            <nav className="flex-1 px-2 pb-4 space-y-1">
              {navItemsMemo.map((item) => {
                // Desktop menu
                let showLockIcon = false

                if (item.admin && !admin) {
                  return null
                }

                if (item.href === '/leads-guide') {
                  showLockIcon = !hasAccessToGuide
                }
                if (item.href === '/leads-forms') {
                  showLockIcon = !hasAccessToForms
                }
                if (item.href === '/buying-intent') {
                  showLockIcon = !hasAccessToBuyingIntent
                }

                if (item.href.includes('/buying-intent-v2')) {
                  showLockIcon = !hasAccessToBuyingSessionIntent
                }

                if (item.href.includes('/profile')) {
                  showLockIcon = !hasAccessToEditProfile
                }

                return !item.children ? (
                  <Link
                    key={`navigation-desktop-${item.name}`}
                    to={showLockIcon ? pathname : item.href}
                  >
                    <span
                      className={cx(
                        'tooltip',
                        item.current
                          ? 'bg-gray-100 text-gray-900'
                          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        item.disabled || showLockIcon
                          ? 'cursor-not-allowed hover:bg-transparent hover:text-gray-600' // pointer-events-none
                          : '',
                        'group relative flex items-center px-2 py-2 text-sm font-medium rounded-md'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? 'text-gray-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'mr-3 flex-shrink-0 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      {t(item.name)}
                      {item.href === '/leads-guide' &&
                        data?.appGuideLeadsNumbers?.result &&
                        data.appGuideLeadsNumbers.result > 0 && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 ml-2">
                            {data.appGuideLeadsNumbers.result}
                          </span>
                        )}
                      {item.href === '/leads-forms' &&
                        data?.appFormLeadsNumbers?.result &&
                        data.appFormLeadsNumbers.result > 0 && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 ml-2">
                            {data.appFormLeadsNumbers.result}
                          </span>
                        )}

                      {(item.disabled || showLockIcon) && !item.underDevelopment && (
                        <div className="ml-auto">
                          <LockClosedIcon
                            className={classNames(
                              item.current
                                ? 'text-gray-500'
                                : 'text-gray-400 group-hover:text-gray-500',
                              'flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                        </div>
                      )}
                      {item.underDevelopment && (
                        <div className="ml-auto">
                          <ExclamationIcon
                            className={classNames(
                              item.current
                                ? 'text-gray-500'
                                : 'text-gray-400 group-hover:text-gray-500',
                              'flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                        </div>
                      )}
                      {item.underDevelopment && (
                        <div
                          role="tooltip"
                          className="tooltip-content pointer-events-none absolute bottom-8 right-0 z-10 py-2 px-3 text-xs text-orange-600 bg-orange-100 rounded-md shadow-sm transition-opacity duration-300"
                        >
                          {t('other.underDevelopmentComingSoon')}
                        </div>
                      )}
                    </span>
                  </Link>
                ) : (
                  <ChildrenNavigation
                    key={item.name}
                    item={item}
                    admin={admin}
                    appAdmin={appAdmin}
                  />
                )
              })}
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}

export default NavSide
