import { PageBody } from '../components/page-body'
import { PageHeader } from '../components/page-header'
import React, { useMemo, useState } from 'react'
import useFetchVendorSystemReviewsInsights from '../hooks/useFetchVendorSystemReviewsInsights'
import useAdminQuery from '../hooks/useAdminQuery'
import { gql } from '@apollo/client'
import SystemsReviewsInsightsFilter from '../components/reviews/your-reviews/insights/filter'
import ReviewAverageQuestionRatingInsight from '../components/reviews/your-reviews/insights/ReviewAverageQuestionRatingInsight'
import { useTranslation } from 'react-i18next'

const GET_SYSTEMS = gql`
  query SystemsData($companyId: ID, $userId: ID, $systemId: ID) {
    appSystemsOnVendor(companyId: $companyId, userId: $userId, systemId: $systemId) {
      id
      systemSiteData {
        id
        locale
        systemName
      }
    }
  }
`

const ReviewInsightsPage = () => {
  const { t } = useTranslation('reviewTemplates')
  const { vendorSystemReviews, loading } = useFetchVendorSystemReviewsInsights()
  const [filteredSystemsReviews, setFilteredSystemsReviews] = useState(null)
  const { data: systems, loading: systemsLoading } = useAdminQuery(GET_SYSTEMS)
  const availableSystems = systems?.appSystemsOnVendor

  const allSystemsReviews = useMemo(() => {
    if (!vendorSystemReviews || !availableSystems) return []

    const appVendorSystemReviewsInsights = Object.values(
      vendorSystemReviews?.appVendorSystemReviewsInsights
    )

    return availableSystems.map((system) => {
      return {
        ...system,
        questions: appVendorSystemReviewsInsights?.find(
          (appVendorSystemReviewsInsight) =>
            appVendorSystemReviewsInsight.systemId == system.id
        )?.questionsInsight
      }
    })
  }, [vendorSystemReviews, availableSystems])

  return (
    <>
      <PageHeader pageName={t('reviewInsights:title')}>
        <p
          className={
            'my-2 text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400'
          }
        >
          {t('reviewInsights:subTitle')}
        </p>
      </PageHeader>
      <PageBody>
        {!loading && (
          <SystemsReviewsInsightsFilter
            allSystemsWithReviews={allSystemsReviews}
            onReviewsFiltered={setFilteredSystemsReviews}
            hideSite={true}
          />
        )}
        <ReviewAverageQuestionRatingInsight systems={filteredSystemsReviews} t={t} />
      </PageBody>
    </>
  )
}

export default ReviewInsightsPage
