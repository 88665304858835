const useReviewInsights = () => {
  /**
   * Compute average rating per question from an array of rating questions
   * @param ratingQuestions
   * @returns {{questionId: *, questionText: *, rating: number|number}[]}
   */
  const getAverageRatingsByQuestion = (uniqueRatingQuestions) => {
    return Object.values(uniqueRatingQuestions).map((uniqueRatingQuestion) => {
      const sum = Object.entries(uniqueRatingQuestion.answers)
        .map(([score, value]) => value * score)
        .reduce((acc, points) => acc + points, 0)

      const votes = Object.entries(uniqueRatingQuestion.answers)
        .map(([, value]) => value)
        .reduce((acc, vote) => acc + vote, 0)

      const rating = !votes ? 0 : Math.round((10 * sum) / votes) / 10

      return {
        ...uniqueRatingQuestion,
        rating
      }
    })
  }

  /**
   * Compute star distribution [1..5] for a single question
   * @param ratingQuestions
   * @param questionId
   * @returns {{star: number, count: *}[]|*[]}
   */
  const getStarDistributionForQuestion = (uniqueRatingQuestions = [], questionId) => {
    if (!questionId) return []

    const question = uniqueRatingQuestions.find((q) => q.questionId === questionId)

    if (!question) return []

    const distribution = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }
    Object.entries(question.answers).forEach(([score, value]) => {
      const star = Number(score)
      if (star >= 1 && star <= 5) {
        distribution[star] = value
      }
    })

    return Object.entries(distribution).map(([star, count]) => ({
      star: `${Number(star)} stars`,
      count
    }))
  }

  /**
   * it gives back questions map
   * @param ratingQuestions
   * @returns {Map<any, any>|{questionId: *, label: *}[]}
   */
  const getUniqueQuestion = (systems, t) => {
    const questions = {}

    Object.values(systems).forEach((system) => {
      system.questions?.forEach((question) => {
        if (!questions[question.questionId]) {
          const answers = {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0
          }

          Object.entries(question.answers)
            .filter(([, value]) => !!value)
            .forEach(([score, value]) => {
              answers[score] = value
            })

          questions[question.questionId] = {
            ...question,
            answers,
            label: t(`${question.questionId}`)
          }
        } else {
          const answers = questions[question.questionId].answers

          Object.entries(question.answers)
            .filter(([, value]) => !!value)
            .forEach(([score, value]) => {
              answers[score] = value + answers[score]
            })

          questions[question.questionId] = {
            ...questions[question.questionId],

            answers
          }
        }
      })
    })

    return Object.values(questions).sort((a, b) => a.label.length - b.label.length)
  }

  return {
    getAverageRatingsByQuestion,
    getStarDistributionForQuestion,
    getUniqueQuestion
  }
}

export default useReviewInsights
