import React, { useEffect, useMemo, useState } from 'react'
import { gql } from '@apollo/client'

import Label from '../../shared/label'
import { MultiSelect, Select } from '../../shared/select'
import AdminField from '../../shared/admin-field'

import Fields from '../../form/fields'
import { profileFields, profileFieldsCategories } from '../../../constants'
import DeleteSystem from './system-form/delete-system'

import { useTranslation } from 'react-i18next'
import { useManageSystemContext } from '../context'
import useFetchOperationalCountries from '../../../hooks/useFetchOperationalCountries'
import useFetchAvailableSites from '../../../hooks/useFetchAvailableSites'
import useFetchVendorsLazy from '../../../hooks/useFetchVendorsLazy'
import useGetCategoryName from '../../../hooks/useGetCategoryName'
import useCategoriesOptions from '../../../hooks/useCategoriesOptions'
import { useUserContext } from '../../../contexts'
import useAdminQuery from '../../../hooks/useAdminQuery'

import { isAdmin } from '../../../utils'

const CURRENT_VENDOR = gql`
  query Vendor {
    appVendor {
      id
      company
    }
  }
`

const SystemFormFields = () => {
  const { t } = useTranslation()
  const {
    selectedCategories,
    setSelectedCategories,
    selectedCountries,
    setSelectedCountries,
    fieldsData,
    handleFieldChange,
    handleChange
  } = useManageSystemContext()
  const { user } = useUserContext()
  const hasAdmin = isAdmin(user?.role)

  const { data: currentVendor } = useAdminQuery(
    CURRENT_VENDOR,
    {
      skip: !hasAdmin
    },
    'cache-first'
  )

  const sortedFields = profileFields.systemFields.sort((a, b) => {
    return (
      profileFieldsCategories[a.category]?.sort -
      profileFieldsCategories[b.category]?.sort
    )
  })

  const [updatedSystemFields, setUpdatedSystemFields] = useState(sortedFields)

  const { systemsMarketRegionsValues } = useFetchOperationalCountries()
  const { sitesOptions } = useFetchAvailableSites()
  const { vendors, fetchVendors, vendorsLoading } = useFetchVendorsLazy()
  const { getCategoryName } = useGetCategoryName()
  const { categoriesOptions } = useCategoriesOptions()

  useEffect(() => {
    setUpdatedSystemFields((prevUpdatedSystemFields) => {
      return prevUpdatedSystemFields
        .map((fieldData) => {
          if (fieldData.id === 'marketRegions') {
            fieldData.values = systemsMarketRegionsValues
          }
          return fieldData
        })
        .flat()
    })
  }, [systemsMarketRegionsValues])

  const selectedCategoriesOptions = useMemo(() => {
    const hasCategories = categoriesOptions.length > 0

    if (!selectedCategories || !selectedCategories.length || !hasCategories) {
      return []
    }

    return selectedCategories.map((category) => {
      const catName = getCategoryName(category)

      return {
        label: catName || category,
        value: category,
        categoryMissing: !catName
      }
    })
  }, [selectedCategories, categoriesOptions, getCategoryName])

  const categoriesOptionsMemo = useMemo(() => {
    if (!hasAdmin) {
      return categoriesOptions
    }

    const missingCategories = selectedCategoriesOptions
      .filter((category) => category.categoryMissing)
      .map((categoryOption) => {
        return {
          ...categoryOption,
          type: 'warning'
        }
      })

    return [...categoriesOptions, ...missingCategories]
  }, [hasAdmin, categoriesOptions, selectedCategoriesOptions])

  const allVendorsOptions = useMemo(() => {
    const options = []
    const thisVendor = currentVendor?.appVendor

    if (!vendors && !thisVendor) {
      return []
    }

    if (thisVendor) {
      options.push({
        label: thisVendor.company,
        value: thisVendor.id
      })
    }

    if (vendors) {
      vendors.forEach((vendor) => {
        options.push({
          label: vendor.company,
          value: vendor.id
        })
      })
    }

    return options
  }, [vendors, currentVendor])

  const handleSelectVendorOpen = () => {
    fetchVendors()
  }
  return (
    <>
      <AdminField>
        <Label title={t('content:category')}>
          <MultiSelect
            options={categoriesOptionsMemo}
            onChange={setSelectedCategories}
            value={selectedCategories}
            placeholder="placeholders.select.select"
          />
        </Label>
        <Label title={t('forms:system.mainCategory')}>
          <Select
            id="defaultCategory"
            options={selectedCategoriesOptions}
            onChange={handleFieldChange}
            value={fieldsData.defaultCategory}
          />
        </Label>
        <Label title={t('forms:system.potentialCategories')}>
          <MultiSelect
            id="potentialCategories"
            options={categoriesOptions}
            onChange={handleChange}
            value={fieldsData.potentialCategories}
            placeholder="placeholders.select.select"
          />
        </Label>
        <Label title={t('content:sites')} helperText={t('forms:system.sitesHelper')}>
          <MultiSelect
            options={sitesOptions}
            onChange={setSelectedCountries}
            value={selectedCountries}
            placeholder="placeholders.select.select"
          />
        </Label>
        <Fields
          inputFields={profileFields.adminFieldsGeneralV2}
          fieldsData={fieldsData}
          handleFieldChange={handleFieldChange}
        />
        <Label title={t('content:company')}>
          <Select
            id="vendorId"
            options={allVendorsOptions}
            onChange={handleFieldChange}
            value={fieldsData.vendorId}
            onDropdownOpen={handleSelectVendorOpen}
            loading={vendorsLoading}
            isClearable
          />
        </Label>
      </AdminField>

      <Fields
        inputFields={updatedSystemFields}
        fieldsData={fieldsData}
        handleFieldChange={handleFieldChange}
      />

      <div className="my-20">
        <DeleteSystem />
      </div>
    </>
  )
}

SystemFormFields.propTypes = {}

SystemFormFields.defaultProps = {}

export default SystemFormFields
