import { gql, useQuery } from '@apollo/client'
import useAdminQuery from '../../../hooks/useAdminQuery'

const BUYING_INTENT_INSIGHTS_QUERY = gql`
  query GetBuyingIntentInsights($companyId: ID, $userId: ID, $systemId: ID) {
    appBuyingIntentV2Insights(
      systemId: $systemId
      userId: $userId
      companyId: $companyId
    ) {
      systemId
      locale
      industry
      companySize
      revenue
      icpMatch
      icpAverage
    }
  }
`

/**
 * Hook to fetch buying intent insights for multiple system IDs
 * @param {string[]} systemIds - Array of system IDs
 * @returns {{ getChartData: function(): object[], loading: boolean, error: ApolloError }}
 */
const useBuyingIntentInsights = () => {
  const { data, loading, error } = useAdminQuery(BUYING_INTENT_INSIGHTS_QUERY, {
    fetchPolicy: 'cache-and-network'
  })

  const getChartData = () => {
    if (loading || error || !data) {
      return
    }

    return data.appBuyingIntentV2Insights.map((insights) => ({
      systemName: insights.systemName,
      icpAverage: insights.icpAverage,
      industry: Array.isArray(insights.industry) ? insights.industry : [],
      revenue: Array.isArray(insights.revenue) ? insights.revenue : [],
      companySize: Array.isArray(insights.companySize) ? insights.companySize : [],
      icpMatch: Array.isArray(insights.icpMatch) ? insights.icpMatch : []
    }))
  }

  return { getChartData, loading, error }
}

export default useBuyingIntentInsights
