import React, { createContext, useState, useMemo } from 'react'
import useBuyingIntentInsights from '../hooks/useBuyingIntentInsights'

export const BuyingIntentInsightContext = createContext(null)

export const BuyingIntentsInsightProvider = ({ children }) => {
  const [selectedSystemId, setSelectedSystemId] = useState(null)
  const { getChartData, loading, error } = useBuyingIntentInsights()

  const combineData = (data) => {
    return data.reduce(
      (acc, system) => {
        // Combine industry
        system.industry.forEach(({ name, count }) => {
          const existing = acc.industry.find((item) => item.name === name)
          if (existing) {
            existing.count += count
          } else {
            acc.industry.push({ name, count })
          }
        })

        // Combine revenue
        system.revenue.forEach(({ range, count }) => {
          const existing = acc.revenue.find((item) => item.range === range)
          if (existing) {
            existing.count += count
          } else {
            acc.revenue.push({ range, count })
          }
        })

        // Combine companySize
        system.companySize.forEach(({ size, count }) => {
          const existing = acc.companySize.find((item) => item.size === size)
          if (existing) {
            existing.count += count
          } else {
            acc.companySize.push({ size, count })
          }
        })

        // Combine icpMatch
        system.icpMatch.forEach(({ bucket, count }) => {
          const existing = acc.icpMatch.find((item) => item.bucket === bucket)
          if (existing) {
            existing.count += count
          } else {
            acc.icpMatch.push({ bucket, count })
          }
        })

        acc.icpTotal = system.icpAverage * system.icpMatch?.length + acc.icpTotal
        acc.icpCount += system.icpMatch?.length

        return acc
      },
      {
        industry: [],
        revenue: [],
        companySize: [],
        icpMatch: [],
        icpTotal: 0,
        icpCount: 0
      }
    )
  }

  const { industry, revenue, companySize, icpMatch, icpTotal, icpCount } = useMemo(() => {
    const chartData = getChartData()

    if (error) {
      return []
    }
    if (!selectedSystemId && chartData) {
      return combineData(chartData)
    }

    const selectedData = chartData?.find((data) => data.systemId === selectedSystemId)
    return (
      selectedData || {
        industry: [],
        revenue: [],
        companySize: [],
        icpMatch: []
      }
    )
  }, [getChartData, selectedSystemId])

  if (error) {
    return <>{error?.message}</>
  }
  return (
    <BuyingIntentInsightContext.Provider
      value={{
        industry,
        revenue,
        companySize,
        icpMatch,
        selectedSystemId,
        icpTotal,
        icpCount,
        setSelectedSystemId
      }}
    >
      {children}
    </BuyingIntentInsightContext.Provider>
  )
}
