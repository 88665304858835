import React, { useContext } from 'react'
import BarChartGraph from '../../../graphs/BarChartGraph'
import { BuyingIntentInsightContext } from '../../context/BuyingIntentInsightContext'

const RevenueChart = ({ t }) => {
  const { revenue } = useContext(BuyingIntentInsightContext)

  const data = revenue
    .map((match) => ({
      name: match.range,
      count: match.count
    }))
    .sort((revenueA, revenueB) => {
      if (revenueA.name.endsWith('+')) {
        return 1
      }

      if (revenueB.name.endsWith('+')) {
        return -1
      }

      const nameA = revenueA.name.split('-')[1]?.replaceAll('M', '') ?? ''
      const nameB = revenueB.name.split('-')[1]?.replaceAll('M', '') ?? ''
      return nameA.localeCompare(nameB)
    })
    .filter((match) => match?.count > 0)

  return (
    <BarChartGraph
      title={t('buyingIntent:filters.revenue')}
      data={data}
      barKey={'count'}
      dataKey={'name'}
      showBarKeyXAxis
      sorted={true}
    />
  )
}

export default RevenueChart
