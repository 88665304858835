import React, { useContext } from 'react'
import ProspectCard from './ProspectCard'
import { BuyingIntentInsightContext } from '../context/BuyingIntentInsightContext'
import { OfficeBuildingIcon, UserIcon, ScaleIcon } from '@heroicons/react/outline'

const ProspectActivityOverview = ({ t }) => {
  const { industry, icpMatch, icpTotal, icpCount } = useContext(
    BuyingIntentInsightContext
  )

  const icpMatchAvg = icpTotal && icpCount ? Math.round(icpTotal / icpCount, 1) : '...'

  const visitors = icpMatch?.length
    ? icpMatch.reduce((acc, icp) => {
        return acc + icp?.count
      }, 0)
    : '...'

  return (
    <div className={'w-full rounded-md shadow bg-white p-5 my-5'}>
      <h3 className={'text-2xl'}>{t('summary.title')}</h3>
      <div
        className={
          'grid grid-cols-3 max-md:grid-cols-1 gap-5 max-md:gap-10 mt-[50px] mb-5'
        }
      >
        <ProspectCard
          className={'rounded-md'}
          title={t('summary.identifiedUsers')}
          counts={visitors}
          icon={<UserIcon width={35} />}
        />
        <ProspectCard
          className={'rounded-md'}
          title={t('summary.identifiedIndustries')}
          counts={industry?.length}
          icon={<OfficeBuildingIcon width={35} />}
        />
        <ProspectCard
          className={'rounded-md'}
          title={t('summary.averageICPMatch')}
          counts={`${icpMatchAvg}%`}
          icon={<ScaleIcon width={35} />}
        />
      </div>
    </div>
  )
}

export default ProspectActivityOverview
