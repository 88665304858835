import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { PieChart, Pie, ResponsiveContainer, Sector } from 'recharts'

import { ChartColors } from './helpers/chartcolors'

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 20) * cos
  const my = cy + (outerRadius + 20) * sin // cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  const lines = payload.name.split(' ')
  return (
    <g>
      {lines.map((line, index) => {
        return (
          <text
            x={cx}
            y={cy - (10 * lines.length) / 2}
            dy={15 * index}
            textAnchor="middle"
            style={{ fontSize: '15px', fontWeight: 'bold' }}
            fill={'#4b5563'}
            key={index}
          >
            {line}
          </text>
        )
      })}

      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill={'#333'}
        style={{ fontSize: '15px', fontWeight: 'bold' }}
      >
        {`${value} (${(percent * 100).toFixed(1)}%)`}
      </text>
    </g>
  )
}

/**
 * Renders a bar chart component displaying buying intent data.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.data - Array of data points used to render the bar chart.
 * @param {string} [props.title] - Optional title text to be displayed above the chart.
 * @param {string} [props.barColor='#3b82f6'] - Color of the bars in the chart.
 * @param {string} [props.barColorText='#fff'] - Color of the text displayed on the bars.
 * @param {string} props.barKey - Key from the data set used as the value for the bar heights.
 * @param {string} props.dataKey - Key from the data set used for categories or labels.
 * @param {boolean} [props.showBarKeyXAxis=false] - Determines whether the bar key is displayed on the X-Axis.
 * @param {boolean} [props.showBarKeyInBar=false] - Determines whether the bar key values are shown inside the bars.
 * @returns {JSX.Element} A styled bar chart component.
 */
const PieChartGraph = ({
  data,
  title,
  height = 400,
  nameKey,
  dataKey,
  innerRadius = 90,
  outerRadius = 130
}) => {
  const coloredData = data.map((d, index) => ({
    ...d,
    fill: ChartColors[index % ChartColors?.length]
  }))

  const highestIndex = data.reduce(
    (acc, item, index) => {
      if (acc[dataKey] < item[dataKey]) {
        return { [dataKey]: item[dataKey], index }
      }

      return acc
    },
    {
      [dataKey]: 0,
      index: 0
    }
  )

  const [activeIndex, setActiveIndex] = useState(highestIndex.index)

  return (
    <div className={'shadow-lg rounded-lg pb-5 bg-white'}>
      {title && (
        <h3
          className={
            'shadow bg-white font-semibold rounded-t-lg capitalize text-sm text-gray-500 p-3'
          }
        >
          {title}
        </h3>
      )}
      <ResponsiveContainer height={height}>
        {!!data.length ? (
          <PieChart width={height} height={height}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={coloredData}
              dataKey={dataKey}
              nameKey={nameKey}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              cx="50%"
              cy="50%"
              label={false}
              onMouseEnter={(_, index) => {
                setActiveIndex(index)
              }}
            />
          </PieChart>
        ) : (
          <div
            className={'flex items-center flex-col text-gray-400 font-semibold text-sm'}
          >
            No Insight available at the moment, come back later.
          </div>
        )}
      </ResponsiveContainer>
    </div>
  )
}

PieChartGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  height: PropTypes.number,
  nameKey: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number
}

export default PieChartGraph
