import React, { useEffect, useMemo, useState } from 'react'
import useReviewInsights from '../../hooks/useReviewInsights'
import BarChartGraph from '../../../../graphs/BarChartGraph'

const DynamicAverageChart = ({ systems, uniqueRatingQuestions, t }) => {
  const { getAverageRatingsByQuestion } = useReviewInsights()
  const [selectedQuestionId, setSelectedQuestionId] = useState('')
  const [selectedRating, setSelectedRating] = useState(5)

  const filteredData = useMemo(() => {
    if (!selectedQuestionId || !uniqueRatingQuestions.length) return []

    const questions = {}

    systems?.map((system) => {
      system?.questions.map((question) => {
        if (!questions[question?.questionId]) {
          questions[question?.questionId] = {
            questionId: question?.questionId,
            reviews: question.reviews
          }
        } else {
          Object.entries(question.reviews).forEach(([reviewId, stars]) => {
            questions[question?.questionId].reviews[reviewId] = stars
          })
        }
      })
    })

    const reviewIds = Object.values(questions)
      .filter((question) => question.questionId == selectedQuestionId)
      .map((question) =>
        Object.entries(question?.reviews).filter(([, stars]) => stars == selectedRating)
      )
      .flat()
      .map(([reviewId]) => reviewId)

    const filteredQuestionAnswers = Object.values(questions).map((question) => {
      const answers = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }
      const reviews = Object.entries(question.reviews).filter(([reviewId, stars]) => {
        return reviewIds.includes(reviewId)
      })

      reviews.forEach(([reviewId, stars]) => {
        if (question.questionId !== selectedQuestionId) {
          answers[stars] += 1
        }
      })

      if (question.questionId === selectedQuestionId) {
        answers[selectedRating] = reviewIds.length
      }

      return {
        questionId: question.questionId,
        label: t(`${question.questionId}`),
        answers
      }
    })

    return getAverageRatingsByQuestion(
      filteredQuestionAnswers.sort((a, b) => a.label.length - b.label.length),
      t
    )
  }, [
    systems,
    uniqueRatingQuestions,
    selectedQuestionId,
    selectedRating,
    getAverageRatingsByQuestion,
    t
  ])

  useEffect(() => {
    if (!selectedQuestionId && uniqueRatingQuestions.length > 0) {
      setSelectedQuestionId(uniqueRatingQuestions[0].questionId)
    }
  }, [selectedQuestionId, uniqueRatingQuestions])

  return (
    <div>
      <div className="mb-4 flex items-center space-x-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            {t('content:question')}
          </label>
          <select
            value={selectedQuestionId}
            onChange={(e) => setSelectedQuestionId(e.target.value)}
            className="border border-gray-300 rounded text-sm text-gray-700
                       focus:outline-none focus:ring-1 focus:ring-indigo-500
                       focus:border-indigo-500"
          >
            {uniqueRatingQuestions.map(({ questionId }) => (
              <option key={questionId} value={questionId}>
                {t(questionId)}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            {t('content:answer')}:
          </label>
          <select
            value={selectedRating}
            onChange={(e) => setSelectedRating(e.target.value)}
            className="border border-gray-300 rounded text-sm text-gray-700
                       focus:outline-none focus:ring-1 focus:ring-indigo-500
                       focus:border-indigo-500"
          >
            {[1, 2, 3, 4, 5].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div>
      </div>
      <BarChartGraph
        data={filteredData}
        barKey="rating"
        dataKey="label"
        title={t('reviewInsights:dynamicAverage.chartTitle')}
        barColor="#10b981"
        barHeight={550}
        gapBottom={120}
        barSize={40}
        rotateXAxisLabels
        showBarKeyXAxis
        showAllXAxisLabels
        sorted={true}
      />
    </div>
  )
}

export default DynamicAverageChart
