import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { array, func } from 'prop-types'

import useGetSystemName from '../../../../hooks/useGetSystemName'

import Filters from '../../../shared/filters'

const SystemsReviewsInsightsFilter = ({ allSystemsWithReviews, onReviewsFiltered }) => {
  const [mappedActiveFilters, setMappedActiveFilters] = useState({})
  const { getSystemName } = useGetSystemName()

  const handleFilterReviews = useCallback(() => {
    // No Filters
    if (!Object.keys(mappedActiveFilters).length) {
      onReviewsFiltered(allSystemsWithReviews)
      return
    }

    const reviewsToSystemId = {}

    allSystemsWithReviews.forEach((system) => {
      const systemsFilter = mappedActiveFilters.system || []
      let matchSystems = false

      if (systemsFilter?.length) {
        matchSystems = systemsFilter.includes(system.id)
      }

      if (matchSystems) {
        reviewsToSystemId[system.id] = system
      }
    })

    const filterResults = Object.values(reviewsToSystemId)

    onReviewsFiltered(filterResults)
  }, [allSystemsWithReviews, mappedActiveFilters, onReviewsFiltered])

  useEffect(() => {
    handleFilterReviews()
  }, [handleFilterReviews])

  const handleChangeFilter = (updatedActiveFilters, updatedMappedActiveFilters) => {
    setMappedActiveFilters(updatedMappedActiveFilters)
  }

  const systemOptionsMemo = useMemo(() => {
    if (!allSystemsWithReviews?.length) {
      return []
    }
    return allSystemsWithReviews.map((system) => {
      return {
        label: getSystemName(system),
        value: system.id
      }
    })
  }, [allSystemsWithReviews, getSystemName])

  const filtersMemo = useMemo(() => {
    const filters = [
      {
        id: 'system',
        nameTid: 'content:system',
        options: systemOptionsMemo
      }
    ]

    return filters
  }, [systemOptionsMemo])

  return <Filters filters={filtersMemo} onChangeFilters={handleChangeFilter} />
}

SystemsReviewsInsightsFilter.propTypes = {
  allSystemsWithReviews: array,
  onReviewsFiltered: func
}

SystemsReviewsInsightsFilter.defaultProps = {}

export default SystemsReviewsInsightsFilter
