import React, { useEffect, useMemo, useState } from 'react'
import useReviewInsights from '../../hooks/useReviewInsights'
import BarChartGraph from '../../../../graphs/BarChartGraph'
import Loader from '../../../../loader'

const DistributionStarChart = ({ uniqueRatingQuestions, t }) => {
  const { getStarDistributionForQuestion } = useReviewInsights()
  const [distributionQuestionId, setDistributionQuestionId] = useState('')

  useEffect(() => {
    if (!distributionQuestionId && uniqueRatingQuestions.length > 0) {
      setDistributionQuestionId(uniqueRatingQuestions[0].questionId)
    }
  }, [distributionQuestionId, uniqueRatingQuestions])

  const distributionData = useMemo(() => {
    return getStarDistributionForQuestion(uniqueRatingQuestions, distributionQuestionId)
  }, [uniqueRatingQuestions, distributionQuestionId, getStarDistributionForQuestion])

  return (
    <div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {t('content:question')}
        </label>
        <select
          value={distributionQuestionId}
          onChange={(e) => setDistributionQuestionId(e.target.value)}
          className="border border-gray-300 rounded text-sm text-gray-700
                     focus:outline-none focus:ring-1 focus:ring-indigo-500
                     focus:border-indigo-500"
        >
          {uniqueRatingQuestions?.map(({ questionId }) => (
            <option key={questionId} value={questionId}>
              {t(`${questionId}`)}
            </option>
          ))}
        </select>
      </div>

      <BarChartGraph
        data={distributionData}
        barKey="count"
        dataKey="star"
        title={t('reviewInsights:distribution.chartTitle')}
        barColor="#f59e0b"
        barHeight={550}
        barSize={40}
        rotateXAxisLabels={false}
        showBarKeyXAxis
      />
    </div>
  )
}

export default DistributionStarChart
