import React, { useEffect, useState } from 'react'
import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import { useTranslation } from 'react-i18next'
import Table from '../components/shared/table'
import useFetchRedirects from '../hooks/useFetchRedirects'
import Loader from '../components/loader'
import Pagination from '../components/shared/pagination'
import Input from '../components/shared/input'

const AdminAllRedirects = () => {
  const { t } = useTranslation()

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [value, setValue] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState('') // State for debounced search
  const { data, totalCount, error, loading } = useFetchRedirects(
    page,
    limit,
    debouncedSearch
  )
  // TODO: add all translations for table header and headings
  const columns = [
    { header: t('content:from'), accessor: 'from' },
    { header: t('content:to'), accessor: 'to' },
    { header: t('content:type'), accessor: 'type' },
    { header: t('content:site'), accessor: 'locale' }
  ]
  // Debounce effect to update the debounced search value
  useEffect(() => {
    if (value.length < 3 && value.length > 0) return
    const handler = setTimeout(() => {
      setDebouncedSearch(value)
    }, 800)

    return () => {
      clearTimeout(handler)
    }
  }, [value])

  return (
    <>
      {/*// TODO: add translations*/}
      <PageHeader pageName={t('adminAllRedirects.header.title')} />
      <PageBody>
        {loading ? (
          <div className={'text-center'}>
            <Loader />
          </div>
        ) : (
          <div className={'bg-white rounded-md shadow-md p-5'}>
            <div className={'flex gap-3 items-center my-4'}>
              <Input
                value={value}
                onChange={setValue} // Update search input
                className={'w-56'}
                // TODO: add translations
                placeholder={t('content:search')}
              />
            </div>
            <Table
              columns={columns}
              data={data?.map(({ from, to, type, locale }) => {
                return { from, to, type, locale }
              })}
            />
            {data?.length > 0 && (
              <Pagination
                page={page}
                limit={limit}
                setLimit={setLimit}
                setPage={setPage}
                totalCount={totalCount}
                className={'mt-10'}
                t={t}
              />
            )}
          </div>
        )}
      </PageBody>
    </>
  )
}

export default AdminAllRedirects
