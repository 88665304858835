import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import svPortal from './locales/sv-SE/portal.json'
import svProducts from './locales/sv-SE/products.json'
import svGuide from './locales/sv-SE/guide.json'
import svShared from './locales/sv-SE/shared.json'
import svReviewTemplates from './locales/sv-SE/review-templates.json'

import enPortal from './locales/en-GB/portal.json'
import enProducts from './locales/en-GB/products.json'
import enGuide from './locales/en-GB/guide.json'
import enShared from './locales/en-GB/shared.json'
import enReviewTemplates from './locales/en-GB/review-templates.json'

import nbPortal from './locales/nb-NO/portal.json'
import nbProducts from './locales/nb-NO/products.json'
import nbGuide from './locales/nb-NO/guide.json'
import nbShared from './locales/nb-NO/shared.json'
import nbReviewTemplates from './locales/nb-NO/review-templates.json'

import daPortal from './locales/da-DK/portal.json'
import daProducts from './locales/da-DK/products.json'
import daGuide from './locales/da-DK/guide.json'
import daShared from './locales/da-DK/shared.json'
import daReviewTemplates from './locales/da-DK/review-templates.json'

const LANG_SWEDISH = 'sv_SE'
const LANG_ENGLISH = 'en_GB'
const LANG_NORWEGIAN = 'nb_NO'
const LANG_DANISH = 'da_DK'

// Used by language switcher
export const AVAILABLE_LOCALE_TRANSLATIONS = [
  LANG_SWEDISH,
  LANG_ENGLISH
  // LANG_NORWEGIAN, // TODO: Add this when translation is OK
  // LANG_DANISH // TODO: Add this when translation is OK
]

const ADDITIONAL_LANG_MAP = {
  // nn_NO: LANG_NORWEGIAN, // TODO: Add this when translation is OK
  // no_NO: LANG_NORWEGIAN // TODO: Add this when translation is OK
}

const resources = {
  [LANG_SWEDISH]: {
    ...svPortal,
    ...svProducts,
    ...svGuide,
    ...svShared,
    ...svReviewTemplates
  },
  [LANG_ENGLISH]: {
    ...enPortal,
    ...enProducts,
    ...enGuide,
    ...enShared,
    ...enReviewTemplates
  },
  [LANG_NORWEGIAN]: {
    ...nbPortal,
    ...nbProducts,
    ...nbGuide,
    ...nbShared,
    ...nbReviewTemplates
  },
  [LANG_DANISH]: {
    ...daPortal,
    ...daProducts,
    ...daGuide,
    ...daShared,
    ...daReviewTemplates
  }
}

const isDevEnv = process.env.REACT_APP_BW_ENV === 'development'
const browserLanguage = (
  window.navigator.userLanguage || window.navigator.language
).split('-')[0]

const LANG_COUNTRY_CODE_MAP = {}

AVAILABLE_LOCALE_TRANSLATIONS.forEach((locale) => {
  const lang = locale.split('_')[0]
  LANG_COUNTRY_CODE_MAP[lang] = locale
})

Object.entries(ADDITIONAL_LANG_MAP).forEach(([countryCode, locale]) => {
  const lang = countryCode.split('_')[0]
  LANG_COUNTRY_CODE_MAP[lang] = locale
})

const detectedLanguage = LANG_COUNTRY_CODE_MAP[browserLanguage] || LANG_ENGLISH

i18n.use(initReactI18next).init({
  resources,
  defaultNS: 'content',
  fallbackLng: isDevEnv ? null : LANG_ENGLISH,
  lng: localStorage.getItem('siteLanguage') || detectedLanguage,
  appendNamespaceToMissingKey: true,
  debug: isDevEnv,
  interpolation: {
    escapeValue: false
  }
})

export default i18n
