import useAdminQuery from './useAdminQuery'
import { gql } from '@apollo/client'

const GET_SYSTEM_VENDOR_REVIEWS_INSIGHTS = gql`
  query AppVendorSystemReviewsInsights($companyId: ID, $userId: ID, $systemId: ID) {
    appVendorSystemReviewsInsights(
      companyId: $companyId
      userId: $userId
      systemId: $systemId
    ) {
      systemId
      questionsInsight {
        answers
        reviews
        questionId
      }
    }
  }
`
export default function useFetchSystemVendorReviewsInsights() {
  const { data, loading } = useAdminQuery(GET_SYSTEM_VENDOR_REVIEWS_INSIGHTS)

  return {
    vendorSystemReviews: data,
    vendorSystemReviewsLoading: loading
  }
}
