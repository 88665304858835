import React, { useContext, useState } from 'react'
import BarHorizontalChart from '../../../graphs/BarHorizontalChart'
import BarChartGraph from '../../../graphs/BarChartGraph'
import { BuyingIntentInsightContext } from '../../context/BuyingIntentInsightContext'

const CompanySizeChart = ({ t }) => {
  const { companySize } = useContext(BuyingIntentInsightContext)

  const useBarHorizontalChart = companySize.length > 5

  const [sortBy, setSortBy] = useState('count')
  let sorted = false

  const sortByOptions = useBarHorizontalChart
    ? [
        {
          id: 'range',
          titleId: 'reviewInsigts:options.sortby.range'
        },
        {
          id: 'count',
          titleId: 'reviewInsigts:options.sortby.quantity'
        }
      ]
    : null
  const sortByCallBack = (sortByOption) => {
    setSortBy(sortByOption)
  }

  const data = companySize
    .filter((companySizeItem) => companySizeItem?.size !== '-')
    .map((companySizeItem) => ({
      ...companySizeItem,
      name: companySizeItem?.size
    }))
    .sort((sizeA, sizeB) => {
      if (!useBarHorizontalChart || sortBy !== 'range') {
        return 0
      }

      const startRangeA = Number.parseInt(sizeA.name.split('-')[0] ?? 0)
      const startRangeB = Number.parseInt(sizeB.name.split('-')[0] ?? 0)

      if (startRangeA > startRangeB) {
        return 1
      }

      if (startRangeA < startRangeB) {
        return -1
      }

      return 0
    })

  if (sortBy !== 'count') {
    sorted = true
  }
  if (!useBarHorizontalChart) {
    return (
      <BarChartGraph
        title={t('buyingIntent:filters.companySize')}
        data={data}
        barKey={'count'}
        dataKey={'name'}
        showBarKeyXAxis
        sorted={true}
      />
    )
  }

  return (
    <BarHorizontalChart
      title={t('buyingIntent:filters.companySize')}
      data={data}
      dataKey={'name'}
      barKey={'count'}
      sorted={sorted}
      sortByOptions={sortByOptions}
      sortBy={'count'}
      sortByCallBack={sortByCallBack}
    />
  )
}

export default CompanySizeChart
