import React, { useMemo } from 'react'
import useReviewInsights from '../hooks/useReviewInsights'
import OverallAverageChart from './charts/OveralAverageChart'
import DynamicAverageChart from './charts/DynamicAverageChart'
import DistributionStarChart from './charts/DistributionStarChart'

import Loader from '../../../../components/loader'

const ReviewAverageQuestionRatingInsight = ({ systems, loading, t }) => {
  const { getAverageRatingsByQuestion, getUniqueQuestion } = useReviewInsights()

  const uniqueRatingQuestions = useMemo(() => {
    if (!systems) {
      return []
    }

    return getUniqueQuestion(systems, t)
  }, [systems, getUniqueQuestion, t])

  const overallAverageData = useMemo(() => {
    return getAverageRatingsByQuestion(uniqueRatingQuestions, t)
  }, [uniqueRatingQuestions, getAverageRatingsByQuestion, t])

  if (loading) {
    return <Loader />
  }

  return (
    <div className="space-y-12">
      <OverallAverageChart data={overallAverageData} t={t} />
      <DistributionStarChart uniqueRatingQuestions={uniqueRatingQuestions} t={t} />
      <DynamicAverageChart
        systems={systems}
        uniqueRatingQuestions={uniqueRatingQuestions}
        t={t}
      />
    </div>
  )
}

export default ReviewAverageQuestionRatingInsight
