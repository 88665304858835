import React, { useState } from 'react'
import { gql } from '@apollo/client'
import useAdminQuery from '../hooks/useAdminQuery'
import useFetchSystems from '../hooks/useFetchSystems'
import useGetSystemName from '../hooks/useGetSystemName'
import { useTranslation } from 'react-i18next'
import { ClipboardCopyIcon, PhoneIcon } from '@heroicons/react/outline'

import { useUserContext } from '../contexts'
import { isAdmin, isAppAdmin } from '../utils'
import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import Expandable from '../components/shared/expandable'
import Button from '../components/shared/button'
import Loader from '../components/loader'
import LeadsGuide from '../components/leadsGuide'
import styles from '../styles/page.module.css'
import EmailStatusExplainListItems from '../components/shared/email/emailStatusExplainListItems'
import Tabs from '../components/shared/tabs'
import CommunicationSettings from '../components/leads-guide/communication-settings'
import AdminField from '../components/shared/admin-field'
import AdminWebhookTriggerStatuses from '../components/admin/admin-webhook-trigger-status'

const QUERY = gql`
  query GuideLeads($companyId: ID, $userId: ID, $locale: String) {
    appGuideLeads(companyId: $companyId, userId: $userId, locale: $locale) {
      id
      category
      companySize
      email
      newEmail
      company
      name
      phone
      ended
      isNew
      role
      locale
      systemId
      emailStatus {
        email
        status
        score
      }
      guideAnswersData {
        questionId
        questionText
        answerText
        specialName
      }
    }
  }
`

const GET_SYSTEMS_NAV = gql`
  query SystemsData($companyId: ID, $userId: ID, $systemId: ID) {
    appSystemsOnVendor(companyId: $companyId, userId: $userId, systemId: $systemId) {
      id
      active
      systemSiteData {
        systemName
        locale
      }
    }
  }
`

function LeadsGuidePage() {
  const { user } = useUserContext()
  const hasAdmin = isAdmin(user?.role)
  const hasAppAdmin = isAppAdmin(user?.appRole)

  const { t, i18n } = useTranslation(['leadsGuide', 'content'])
  const [emailExplainIsOpen, setEmailExplainIsOpen] = useState(false)
  const [activeTab, setActiveTab] = useState('VERIFIED')

  const { availableSystems } = useFetchSystems(GET_SYSTEMS_NAV, 'cache-first')
  const { getSystemName } = useGetSystemName()
  const { data, loading, error } = useAdminQuery(QUERY, {
    variables: {
      locale: i18n.language
    }
  })
  const verifiedLeads = data?.appGuideLeads.filter(
    (lead) => lead.emailStatus?.score === 100
  )

  const unVerifiedLeads = data?.appGuideLeads.filter(
    (lead) => lead.emailStatus?.score !== 100
  )

  const tabOptions = [
    { label: t('tabs.verified'), value: 'VERIFIED' },
    { label: t('tabs.unverified'), value: 'UNVERIFIED' }
  ]

  if (hasAdmin || hasAppAdmin) {
    tabOptions.push({ label: t('tabs.settings'), value: 'SETTINGS' })
  }

  const systemsArray = availableSystems?.map((system) => ({
    id: system.id,
    name: getSystemName(system)
  }))

  const handleTabChange = (tab) => {
    setActiveTab(tab.value)
  }

  return (
    <>
      <PageHeader pageName={t('header.title')}>
        <AdminField>
          <AdminWebhookTriggerStatuses guideLead />
        </AdminField>
        <p className="mb-4">{t('header.description')}</p>
        <Button
          size="small"
          mode="default"
          onClick={() => setEmailExplainIsOpen(!emailExplainIsOpen)}
        >
          {t('iconsExplained', { ns: 'content' })}
        </Button>
        <Expandable className="mt-2 text-sm" open={emailExplainIsOpen}>
          <ul className="mb-4 list-none list-inside">
            <li className="pl-4 mb-1">
              <ClipboardCopyIcon
                className="inline-flex h-5 w-5 mr-2 text-gray-400"
                aria-hidden="true"
              />
              = {t('copyIcon', { ns: 'content' })}
            </li>
            <EmailStatusExplainListItems />
            <li className="pl-4 mb-1">
              <PhoneIcon
                className="inline-flex flex-shrink-0 h-5 w-5 mr-2 text-gray-400"
                aria-hidden="true"
              />
              = {t('phoneIcon', { ns: 'content' })}
            </li>
          </ul>
        </Expandable>
        <p>{t('header.subtitle')}</p>
      </PageHeader>

      <PageBody>
        {loading && (
          <div className="text-center">
            <Loader />
          </div>
        )}
        {error && (
          <div style={{ textAlign: 'center' }} className={styles.errorMsg}>
            {t('content:error.somethingWentWrongTryAgainLater')}
            <br />
            <i>
              <small>({error.message})</small>
            </i>
          </div>
        )}

        {!loading && (
          <>
            <Tabs tabs={tabOptions} activeTab={activeTab} onChange={handleTabChange} />

            {activeTab == 'VERIFIED' && (
              <LeadsGuide
                leads={verifiedLeads}
                systemsArray={systemsArray}
                isAdmin={hasAdmin}
              />
            )}

            {activeTab == 'UNVERIFIED' && (
              <LeadsGuide
                leads={unVerifiedLeads}
                systemsArray={systemsArray}
                isAdmin={hasAdmin}
              />
            )}

            {activeTab == 'SETTINGS' && <CommunicationSettings />}
          </>
        )}
      </PageBody>
    </>
  )
}

export default LeadsGuidePage
