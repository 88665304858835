import React from 'react'
import PropTypes from 'prop-types'
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  YAxis,
  LabelList,
  Tooltip,
  XAxis,
  Rectangle
} from 'recharts'
import { Select } from '../form/inputs'
import { ChartColors } from './helpers/chartcolors'

const BarHorizontalChart = ({
  data,
  title,
  dataKey,
  barKey,
  sorted = false,
  sortByOptions = null,
  sortBy = null,
  sortByCallBack = () => {}
}) => {
  const BarChartLabel = (props) => {
    const { x, y, value } = props
    const newX = x + 5
    const newY = y - 7
    return (
      <text
        x={newX}
        y={newY}
        textAnchor="start"
        fill="#333"
        style={{ fontSize: '15px', fontWeight: 'bold' }}
      >
        {value}
      </text>
    )
  }

  const BarChartValue = (props) => {
    const { y, value, viewBox } = props

    const newX = viewBox.x + viewBox.width + 9 //  (valueChars?.length * 12 + 12)
    const newY = y + viewBox.height / 2
    return (
      <text
        x={newX}
        y={newY}
        textAnchor="start"
        fill="#4b5563"
        style={{ fontSize: '15px', fontWeight: 'bold' }}
      >
        {value}
      </text>
    )
  }

  const coloredData = data
    .map((data, index) => ({
      ...data,
      fill: ChartColors[index % ChartColors?.length]
    }))
    .sort((industryA, industryB) => {
      return sorted
        ? 0
        : industryA[barKey] < industryB[barKey]
        ? 1
        : industryA[barKey] > industryB[barKey]
        ? -1
        : 0
    })

  return (
    <div className={'shadow-lg rounded-lg bg-white'}>
      <div className="grid grid-cols-1 md:grid-cols-2 shadow bg-white gap-0 p-0">
        <div className="p-3">
          <h3 className={'font-semibold rounded-t-lg capitalize text-sm text-gray-500'}>
            {title}
          </h3>
        </div>
        <div className="justify-items-end items-center">
          <div className="pt-1 pr-1">
            {!!sortByOptions && (
              <Select
                field={{
                  id: 'sortBy',
                  type: 'select',
                  values: sortByOptions
                }}
                classNames="m-0"
                data={sortBy}
                handleFieldChange={(e) => {
                  sortByCallBack(e.target.value)
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="h-fit overflow-hidden">
        <div className="overflow-y-auto scrollbar w-full max-h-96">
          <ResponsiveContainer width={'100%'} height={80 * data.length} debounce={50}>
            <BarChart
              data={coloredData}
              layout="vertical"
              margin={{ left: -25, right: 40, top: 30 }}
            >
              <XAxis type="number" axisLine={false} display="none" />
              <YAxis type="category" axisLine={false} display="none" />
              <Tooltip />
              <Bar
                radius={[0, 7, 7, 0]}
                dataKey={barKey}
                minPointSize={1}
                activeBar={<Rectangle fill="#4f46e5" />}
                barSize={40}
                dy={10}
              >
                <LabelList
                  dataKey={dataKey}
                  position="top"
                  fill="#262626"
                  dy={-20}
                  content={<BarChartLabel x={-1} y={-1} width={-1} value={-1} />}
                />
                <LabelList
                  dataKey={barKey}
                  position="right"
                  textAnchor="middle"
                  fill={'#fff'}
                  dx={0}
                  content={<BarChartValue />}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}

BarHorizontalChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  barKey: PropTypes.string.isRequired,
  sorted: PropTypes.bool,
  sortByOptions: PropTypes.arrayOf(PropTypes.object),
  sortBy: PropTypes.string,
  sortByCallBack: PropTypes.func
}

export default BarHorizontalChart
