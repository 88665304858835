import React from 'react'
import PropTypes from 'prop-types'
import { EyeIcon } from '@heroicons/react/solid'

const ProspectCard = ({ title, counts, icon, className }) => {
  return (
    <div className={`${className} w-full flex`}>
      <div className="flex w-full max-w-full flex-col break-words rounded-md border border-gray-100 bg-white text-gray-600 shadow-lg">
        <div className="p-3">
          <div className="flex items-center justify-center flex-col -mt-10 h-16 w-16 rounded-md bg-blue-500 text-white shadow-md">
            {icon}
          </div>
          <div className="pt-1 text-right">
            <h4 className="text-2xl font-semibold tracking-tighter xl:text-2xl flex gap-3 justify-end text-gray-600">
              {counts} <EyeIcon className={'text-gray-400'} width={15} />
            </h4>
          </div>
        </div>
        <hr className="opacity-50" />
        <div className="p-4">
          <p className="font-light">
            <span className="text-sm font-bold text-gray-500">{title} </span>
          </p>
        </div>
      </div>
    </div>
  )
}

ProspectCard.propTypes = {
  title: PropTypes.string.isRequired,
  counts: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.element.isRequired,
  className: PropTypes.string
}

export default ProspectCard
