import React from 'react'
import BarChartGraph from '../../../../graphs/BarChartGraph'

const OverallAverageChart = ({ data, t }) => {
  return (
    <BarChartGraph
      data={data}
      barKey="rating"
      dataKey="label"
      title={t('reviewInsights:average.title')}
      barHeight={550}
      gapBottom={120}
      barSize={40}
      rotateXAxisLabels
      showBarKeyXAxis
      showAllXAxisLabels
      sorted={true}
    />
  )
}

export default OverallAverageChart
