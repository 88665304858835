import React, { useState } from 'react'
import { PageHeader } from '../components/page-header'
import Expandable from '../components/shared/expandable'
import { Trans, useTranslation } from 'react-i18next'
import { PageBody } from '../components/page-body'
import BuyingIntentActivities from '../components/buying-intents/BuyingIntentActivities'
import { useParams } from 'react-router-dom'

import CustomerStagesExplainer from '../components/leads-guide/customer-stages-explainer'
const BuyingIntentV2Page = () => {
  const { t } = useTranslation('buyingIntent')
  const { systemId } = useParams()
  const [whatIsOpen, setWhatIsOpen] = useState(false)
  //
  return (
    <>
      <PageHeader
        pageName={t('buyingIntents:header.title')}
        headerClassName="flex items-end gap-1"
        headerRightComponent={
          <button
            className="border-none underline text-sm p-1 text-indigo-600 leading-none"
            onClick={() => setWhatIsOpen(!whatIsOpen)}
          >
            {t('buyingIntent:header.what.title_1')}
          </button>
        }
      >
        <p className={'my-2'}>{t('buyingIntent:header.subtitle')}</p>
        <Expandable className="mt-2 text-sm" open={whatIsOpen}>
          <div className="p-3 border rounded">
            <p className="font-medium">{t('buyingIntents:header.what.title_1')}</p>
            <Trans i18nKey="buyingIntents:header.what.text_1" components={{}} />
            <p className="font-medium mt-3">{t('buyingIntents:header.what.title_2')}</p>
            <Trans
              i18nKey="buyingIntents:header.what.text_2"
              components={{
                ul: <ul className="list-disc ml-6 mt-2" />,
                li: <li />
              }}
            />
            <p className="font-medium mt-3">{t('buyingIntents:header.what.title_3')}</p>
            <Trans
              i18nKey="buyingIntents:header.what.text_3"
              components={{
                ul: <ul className="list-disc ml-6 mt-2" />,
                li: <li />
              }}
            />
            <div className="flex gap-2 my-2">
              <CustomerStagesExplainer
                specialName={'STATE_AWARENESS'}
                noStateDecoration={true}
              />
              <div className="w-1/2 hidden md:block"></div>
            </div>
            <p className="font-medium mt-3">{t('buyingIntents:header.what.title_4')}</p>
            <Trans
              i18nKey="buyingIntents:header.what.text_4"
              components={{
                ul: <ul className="list-disc ml-6 mt-2" />,
                li: <li />
              }}
            />{' '}
            <p>
              <Trans
                i18nKey="buyingIntents:header.what.text_4b"
                components={{
                  ul: <ul className="list-disc ml-6 mt-2" />,
                  li: <li />
                }}
              />
            </p>
          </div>
        </Expandable>
      </PageHeader>
      <PageBody>
        <BuyingIntentActivities systemId={systemId} />
      </PageBody>
    </>
  )
}

export default BuyingIntentV2Page
