import React, { useState } from 'react'
import { gql } from '@apollo/client'
import useAdminQuery from '../../hooks/useAdminQuery'
import { useTranslation } from 'react-i18next'
import BuyingIntentFilters from './BuyingIntentFilters'

import GenerateCsvButton from '../shared/generate-csv-btn'
import BuyingIntent from './BuyingIntent'
import Loader from '../loader'
import Pagination from '../shared/pagination'
import useGetSystemName from '../../hooks/useGetSystemName'
import { useUserContext } from '../../contexts'
import { isAdmin } from '../../utils'

const GET_BUYING_INTENTS = gql`
  query GetBuyingIntent(
    $companyId: ID
    $userId: ID
    $page: Int
    $limit: Int
    $systemId: ID
  ) {
    appBuyingIntentV2(
      companyId: $companyId
      userId: $userId
      page: $page
      limit: $limit
      systemId: $systemId
    ) {
      activities {
        id
        originalId
        status
        name
        activity
        category
        bwBranch
        companySize
        locales
        site
        linkedInUrl
        awareness
        decision
        consideration
        stage
        stagePercentage
        revenue
        lastSeen
        buyingIntentActivityToSystem {
          id
          systemId
          customerProfileMatch
          systemNotificationStatus
          systemSiteData {
            systemName
          }
        }
      }
      totalCount
      hasAccess
    }
  }
`

const BuyingIntentActivities = ({ systemId }) => {
  const { t } = useTranslation('buyingIntent')
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(50)
  const [totalCount, setTotalCount] = useState(0)
  const [buyingIntents, setBuyingIntents] = useState([])
  const [hasAccess, setHasAccess] = useState(false)
  const [filteredBuyingIntents, setFilteredBuyingIntents] = useState(null)

  const { user } = useUserContext()
  const hasAdmin = isAdmin(user?.role)

  const { getSystemName } = useGetSystemName()

  const { loading, error } = useAdminQuery(GET_BUYING_INTENTS, {
    variables: {
      systemId,
      page,
      limit
    },
    onCompleted: ({ appBuyingIntentV2 }) => {
      setBuyingIntents(appBuyingIntentV2.activities)
      setTotalCount(appBuyingIntentV2.totalCount)
      setHasAccess(appBuyingIntentV2.hasAccess)
    },
    onError: (error) => {
      console.error('Error fetching buying intents:', error)
    }
  })

  const csvData = {
    headers: [
      { label: 'Company', key: 'name' },
      { label: 'System', key: 'system' },
      { label: 'Category', key: 'category' },
      { label: 'Company Size', key: 'companySize' },
      { label: 'Revenue from', key: 'revenueFrom' },
      { label: 'Revenue to', key: 'revenueTo' },
      { label: 'Website', key: 'site' },
      { label: 'Activity', key: 'activity' },
      { label: 'Stage', key: 'stage' },
      { label: 'Stage Percentage', key: 'stagePercentage' },
      { label: 'Customer Profile Match (%)', key: 'customerProfileMatch' }
    ],
    data: buyingIntents?.map(
      ({
        name,
        category,
        site,
        activity,
        stage,
        stagePercentage,
        companySize,
        revenue,
        buyingIntentActivityToSystem
      }) => {
        return {
          name,
          system: getSystemName(buyingIntentActivityToSystem),
          category,
          site,
          activity:
            activity <= 25
              ? 'LOW'
              : activity > 25 && activity <= 75
              ? 'MEDIUM'
              : activity > 75
              ? 'HIGH'
              : 'No Data',
          stage: stage,
          stagePercentage: stagePercentage,
          companySize,
          revenueFrom: revenue?.min,
          revenueTo: revenue?.max,
          customerProfileMatch: buyingIntentActivityToSystem?.customerProfileMatch
        }
      }
    )
  }
  if (!hasAccess && !hasAdmin && !loading) return <div>{t('content:warning.noData')}</div>
  return (
    <div>
      <div>{hasAdmin && !loading ? `Has access: ${hasAccess}` : ''}</div>
      <div>
        <div>
          <BuyingIntentFilters
            buyingIntents={buyingIntents || []}
            updateBuyingIntents={setFilteredBuyingIntents}
          />
        </div>
        <div className={'text-end'}>
          <GenerateCsvButton
            filename="buying-intents.csv"
            csvData={csvData}
            gaActionName="export_buying_intents_csv"
          />
        </div>
        <div className="bg-white shadow overflow-hidden mt-3 sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {' '}
            {!loading ? (
              !(filteredBuyingIntents || buyingIntents)?.length ? (
                `${t('content:warning.noData')} ${error?.message || ''}`
              ) : (
                (filteredBuyingIntents || buyingIntents).map((buyingIntent) => {
                  return (
                    <BuyingIntent
                      key={buyingIntent.id}
                      signal={buyingIntent}
                      systemId={systemId}
                    />
                  )
                })
              )
            ) : (
              <Loader />
            )}
          </ul>
        </div>
        {buyingIntents?.length > 0 && (
          <Pagination
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={totalCount}
            t={t}
          />
        )}
        <div className="w-full flex flex-col gap-3"></div>
      </div>
    </div>
  )
}

export default BuyingIntentActivities
