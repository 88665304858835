import React, { useContext } from 'react'
import BarChartGraph from '../../../graphs/BarChartGraph'
import BarHorizontalChart from '../../../graphs/BarHorizontalChart'
import { BuyingIntentInsightContext } from '../../context/BuyingIntentInsightContext'

const IndustryChart = ({ t }) => {
  const { industry } = useContext(BuyingIntentInsightContext)

  const data = industry
    .filter((industry) => industry?.name !== 'Unknown')
    .map((industry, index) => {
      return {
        ...industry,
        name:
          industry?.name == 'Unknown'
            ? 'N/A'
            : t(`forms:system.branch.${industry?.name}`),
        count: industry.count
      }
    })

  if (data.length <= 3) {
    return (
      <BarChartGraph
        data={data}
        title={t('content:industry')}
        dataKey={'name'}
        barKey={'count'}
        gapBottom={data?.length > 3 ? 140 : null}
        rotateXAxisLabels={data?.length > 3 ? true : false}
        showBarKeyXAxis
      />
    )
  } else {
    return (
      <BarHorizontalChart
        title={t('content:industry')}
        data={data}
        dataKey={'name'}
        barKey={'count'}
      />
    )
  }
}
export default IndustryChart
