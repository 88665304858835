import React from 'react'
import { BuyingIntentsInsightProvider } from '../components/buying-intents/context/BuyingIntentInsightContext'
import ProspectActivityOverview from '../components/buying-intents/buying-intent-insights/ProspectActivityOverview'
import IndustryChart from '../components/buying-intents/buying-intent-insights/charts/IndustryChart'
import CompanySizeChart from '../components/buying-intents/buying-intent-insights/charts/CompanySizeChart'
import RevenueChart from '../components/buying-intents/buying-intent-insights/charts/RevenueChart'
import ICPChart from '../components/buying-intents/buying-intent-insights/charts/ICPChart'
import { PageBody } from '../components/page-body'
import { PageHeader } from '../components/page-header'
import { useTranslation } from 'react-i18next'

const BuyingIntentV2InsightsPage = () => {
  const { t } = useTranslation('buyingIntentInsights')
  return (
    <>
      <PageHeader pageName={t('title')} />
      <PageBody>
        <BuyingIntentsInsightProvider>
          <ProspectActivityOverview t={t} />
          <div className={'grid grid-cols-2 max-md:grid-cols-1 gap-3'}>
            <IndustryChart t={t} />
            <ICPChart t={t} />
            <RevenueChart t={t} />
            <CompanySizeChart t={t} />
          </div>
        </BuyingIntentsInsightProvider>
      </PageBody>
    </>
  )
}

export default BuyingIntentV2InsightsPage
